import React, { useEffect, useState, useRef } from 'react';
import './Timer.css';
import logo from './logo.png';
import bg from './bg.png';

const phrases = [
  "Look mom, I made it to Hackmageddon Chapter 1",
  "If it works, don't touch it.",
  "Compiling creativity...",
  "Debugging the universe...",
  "Turning coffee into code...",
  "Did you try turning it off and on again?",
  "404: Sleep not found",
  "This is not a bug, it's a feature!",
  "Hack it 'til you make it!",
  "Code, coffee, repeat!",
  "In case of fire: git commit, git push, leave building.",
  "Coding is 10% writing and 90% debugging.",
  "Keep calm and code on!",
  "Real hackers count from 0.",
  "Let's make some bugs!",
  "Eat. Sleep. Code. Repeat.",
  "Semicolons are for wimps.",
];

function Timer() {
  const [timeLeft, setTimeLeft] = useState({});
  const [phrase, setPhrase] = useState(phrases[0]);
  const logoRef = useRef(null);
  const containerRef = useRef(null);

  const targetDate = new Date('2024-08-11T10:00:00+05:30');

  useEffect(() => {
    // Countdown Timer Logic
    const timerId = setInterval(() => {
      const now = new Date();
      const distance = targetDate - now;

      if (distance < 0) {
        setTimeLeft({
          hours: '00',
          minutes: '00',
          seconds: '00',
          expired: true,
        });
        clearInterval(timerId);
      } else {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft({
          hours: String(hours).padStart(2, '0'),
          minutes: String(minutes).padStart(2, '0'),
          seconds: String(seconds).padStart(2, '0'),
          expired: false,
        });
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [targetDate]);

  useEffect(() => {
    const logo = logoRef.current;
    const container = containerRef.current;

    if (!logo || !container) return;

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const logoWidth = logo.clientWidth;
    const logoHeight = logo.clientHeight;

    let posX = Math.random() * (containerWidth - logoWidth);
    let posY = Math.random() * (containerHeight - logoHeight);
    let speedX = Math.random() < 0.5 ? 2 : -2;
    let speedY = Math.random() < 0.5 ? 2 : -2;

    function animate() {
      posX += speedX;
      posY += speedY;

      // Bounce off the left/right edges
      if (posX <= 0 || posX + logoWidth >= containerWidth) {
        speedX = -speedX;
        posX = Math.max(0, Math.min(posX, containerWidth - logoWidth));
      }

      // Bounce off the top/bottom edges
      if (posY <= 0 || posY + logoHeight >= containerHeight) {
        speedY = -speedY;
        posY = Math.max(0, Math.min(posY, containerHeight - logoHeight));
      }

      logo.style.transform = `translate(${posX}px, ${posY}px)`;

      requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * phrases.length);
      setPhrase(phrases[randomIndex]);
    }, 5000); // Change phrase every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="timer__container" ref={containerRef}>
      <img src={bg} alt="timer_bg" className="timer__bg" />
      <img src={logo} alt="timer_logo" className="timer__logo" ref={logoRef} />
      <h3 className="timer__text">
        {timeLeft.expired ? 'EXPIRED' : `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
      </h3>
      <div className="phrase">{phrase}</div>
    </div>
  );
}

export default Timer;