import './Marque.css'
import React from 'react'

const Marque = () => {
    return (
        <div className='logos__slides'>
            <div className="logos__slide">
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
                <span className='logos__text'>Results Are Out!</span>
            </div>
        </div>

    )
}

export default Marque